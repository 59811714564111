export const mongoObjectId = () => {
    var timestamp = (new Date().getTime() / 1000 | 0).toString(16)
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
        return (Math.random() * 16 | 0).toString(16)
    }).toLowerCase()
}

export const QTR_V = (m3, B2) => {
    var result = ""
    if (m3 === "") {
        // If m3 is blank
        result = " "
    } else if (m3 === "QUARTER" || m3 === "HALFTIME") {
        // If m3 is "QUARTER" or "HALFTIME"
        if (Math.min(Number(B2) + 1, 5) === 5) {
            result = "OT"
        } else {
            result = Math.min(Number(B2) + 1, 5)
        }
    } else {
        // For other cases
        result = B2
    }
    return result
}

export const PDOWN_V = (E2, E3) => {
    if ((E2 === "K" && (E3 === "O" || E3 === "D")) || (E2 === "O" && E3 === "D") || (E2 === "D" && E3 === "O")) {
        return "P"
    } else {
        return ""
    }
}

// Example usage:
// let E2 = "K"
// let E3 = "O"
// console.log(evaluateFormula(E2, E3)) // Output: "P"

export const OPPTEAM = (e3, a2) => {
    // Check if e3 is one of the specific values and return a2 if true
    if (["O", "D", "K", "S"].includes(e3.toUpperCase())) {
        return a2
    }
    // Return a space if e3 is not one of the values or if an error would occur
    return ""
}//OPP TEAM
///D/B/C/I/E/F
export const CV = (E2, D2) => {
    console.log("cv=========", E2, "D2---:", D2)
    if (E2 === "O" || E2 === "D" || E2 === "K" || E2 === "S") {
        if (D2 == " ") {
            return " "
        }
        return Number(D2) + 1
    } else {
        return " "
    }
}

export const ODK = (u3, m3, l3, k3) => {
    // console.log("DV ============001", "u3", u3, "m3", m3, "l3", l3, "k3", k3)
    // Check if u3 contains text and return "O"
    if (typeof u3 === 'string' && u3.trim().length > 0) {
        // console.log("DV ============002", u3, m3, l3, k3)
        return "O"
    }
    // Check if m3 contains text and return "D"
    else if (typeof m3 === 'string' && m3.trim().length > 0) {
        // console.log("DV ============003", u3, m3, l3, k3)
        return "D"
    }
    // Check if l3 is "QUARTER" or "HALFTIME" and return "S"
    else if (l3 === "QUARTER" || l3 === "HALFTIME" || l3 === "TIMEOUT") {
        // console.log("DV ============004", u3, m3, l3, k3)
        return "S"
    }
    // Check multiple conditions in k3 and return "K"
    else if (["PUNT", "KO", "KOR", "PUNT REC", "EXTRA PT", "EXTRA PT BLOCK", "FG", "FG BLOCK"].includes(k3.toUpperCase())) {
        // console.log("DV ============005", u3, m3, l3, k3)
        return "K"
    }
    // If none of the above conditions are met, return a space
    else {
        // console.log("DV ============006", u3, m3, l3, k3)
        return " "
    }
}

export const DN_V = (K2, E2, G3, I2, F2) => {
    console.log("ev ============001", K2, E2, G3, I2, F2)
    if (K2 === "INCOMPLETE") {
        console.log("ev ============002", typeof E2)
        return parseInt(E2) + 1
    } else if (G3 === "") {
        console.log("ev ============003")
        return ""
    } else if (K2 === "TIMEOUT" || K2==="QUARTER") {
        console.log("ev ============004")
        return E2
    } else if (K2 === "PENALTY" && I2 >= F2) {
        console.log("ev ============005")
        return 1
    } else if (K2 === "PENALTY" && I2 < F2) {
        console.log("ev ============006", I2, F2)
        return E2
    } else if (["PUNT", "NO GOOD", "GOOD"].includes(K2.toUpperCase())) {
        console.log("ev ============007")
        return ""
    } else if (["FUMBLE", "INTERCEPTION", "INCOMPLETE,TO", "RUSH,TO",
        "SACK,TO", "FUMBLE,TD", "COMPLETE,TD", "FUMBLE,DEF TD", "SAFETY",
        "COMPLETE,TO", "PUNT", "RETURN", "FAIR CATCH", "SACK,TO",
        "INTERCEPTION,TD", "RETURN", "OUT OF BOUNDS", "DOWNED",
        "TOUCHBACK"].includes(K2.toUpperCase())) {
        console.log("ev ============008")
        return 1
    } else if (I2 < F2) {
        console.log("ev ============009")
        return Number(E2) + 1
    } else {
        console.log("ev ============0010")
        return 1
    }
}//DN

export const DIST_V = (G2, G3, H3, F2, F3, J2, L2) => {
    console.log("Dist_v========000:", "G2", G2, "G3", G3, "H3", H3, "F2", F2, "F3", F3, "J2", J2, "L2", L2)
    if (L2 === "INCOMPLETE") {
        console.log("Dist_v========0010:")
        return G2
    } else if (H3 === "") {
        console.log("Dist_v========001:")
        return ""
    } else if (["FUMBLE", "INTERCEPTION", "INCOMPLETE,TO", "RUSH,TO", "SACK,TO",
        "COMPLETE,TO", "PUNT", "RETURN", "FAIR CATCH", "SACK,TO",
        "RETURN", "OUT OF BOUNDS", "DOWNED", "TOUCHBACK"].includes(L2)) {
        console.log("Dist_v========002:")
        return 10
    } else if (Number(F2) === 4 && Number(F3) === 1) {
        console.log("Dist_v========003:")
        return 10
    } else if (Number(J2) <= 0) {
        console.log("Dist_v========004:")
        return Number(G2) - Number(J2)
    } else if (Number(J2) > 0 && Number(G2) > Number(J2)) {
        console.log("Dist_v========005:")
        return Number(G2) - Number(J2)
    } if (G3 > H3) {
        return H3
    } else {
        console.log("Dist_v========006:")
        return 10
    }
}//DIST

export const GNLS_V = (l3, G3, G4) => {
    console.log("iv ======== 000", "l3", l3, "G3:", G3, "G4:", G4)
    if (l3 === "INCOMPLETE") {
        console.log("iv ======== 001", "l3", l3, "G3:", G3, "G4:", G4)
        return 0
    } else if (["FAIR CATCH", "DOWNED", "OUT OF BOUNDS", "RETURN"].includes(l3.toUpperCase())) {
        console.log("iv ======== 002", "l3", l3, "G3:", G3, "G4:", G4)
        return 0
    } else if (G3 === "") {
        console.log("iv ======== 003", "l3", l3, "G3:", G3, "G4:", G4)
        return ""
    } else if (["RUSH,TO", "FUMBLE", "INTERCEPTION", "INCOMPLETE,TO", "COMPLETE,TO",
        "SACK,TO", "NO GOOD", "PUNT", "INTERCEPTION,TD", "FUMBLE,TD", "FUMBLE,DEF TD"].includes(l3.toUpperCase())) {
        console.log("iv ======== 004", "l3", l3, "G3:", G3, "G4:", G4)
        return 0
    } else if ((l3 === "COMPLETE,TD" || l3 === "COMPLETE ,TD" || l3 === "RUSH,TD" || l3 === "SCRAMBLE,TD") && Number(G3) < 0) {
        console.log("iv ======== 005", "l3", l3, "G3:", G3, "G4:", G4)
        return Number(G3) + 100
    } else if ((l3 === "RUSH,TD" || l3 === "SCRAMBLE,TD" || l3 === "COMPLETE,TD") && Number(G3) > 0) {
        console.log("iv ======== 006", "l3", l3, "G3:", G3, "G4:", G4)
        return G3
    } else if (Number(G3) < 0 && Number(G4) < 0 && Number(G3) >= Number(G4)) {
        console.log("iv ======== 007", "l3", l3, "G3:", G3, "G4:", G4)
        return (Number(G4) - Number(G3)) * -1
    } else if (Number(G3) < 0 && Number(G4) > 0) {
        console.log("iv ======== 008", "l3", l3, "G3:", G3, "G4:", G4)
        return Number(G3) + 50 + (50 - Number(G4))
    } else if (Number(G3) > 0 && Number(G4) > 0 && Number(G3) <= Number(G4)) {
        console.log("iv ======== 009", "l3", l3, "G3:", G3, "G4:", G4)
        return Number(G3) - Number(G4)
    } else if (Number(G3) < 0 && Number(G4) < 0 && Number(G3) < Number(G4)) {
        console.log("iv ======== 0010", "l3", l3, "G3:", G3, "G4:", G4)
        return Number(G3) - Number(G4)
    } else if (Number(G3) > 0 && Number(G4) > 0 && Number(G3) > Number(G4)) {
        console.log("iv ======== 0011", "l3", l3, "G3:", G3, "G4:", G4)
        return Number(G3) - Number(G4)
    } else if (Number(G3) > 0 && Number(G4) < 0) {
        console.log("iv ======== 0012", "l3", l3, "G3:", G3, "G4:", G4)
        return ((Number(G4) + 50) * -1) - (50 - Number(G3))
    } else {
        console.log("iv ======== 0013", "l3", l3, "G3:", G3, "G4:", G4)
        return "Next Play" // Default case if none of the conditions are met
    }
}//GN/LS


export const  get_gn_ls = (value) => {
    if(value>=20){
        return '#00ff00'
    }else if(value<=-5){
        return '#ff0000'
    }else if(value>15){
        return '#66ff66'
    }else if(value>10){
        return '#ccff66'
    }else if(value>5){
        return '#ffcc66'
    }else if(value>0){
        return '#ff9933'
    }else if(value>-5){
        return '#ff6600'
    }

}