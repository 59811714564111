import React, { useState, useRef, useEffect } from 'react'
import { MdDeleteOutline } from "react-icons/md"
// import SearchSelect from './../searchSelect'
import SearchSelect from '../searchSelect'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { dropdownInputSelector, sheetStatusSelector, liveDataSelector, sheetNameSelector } from 'redux/modules/sheet/selectors'
import { getDropdownInput, updateDropdownInput, createLiveData, updateLiveData, getLiveData, clearLiveData } from 'redux/modules/sheet/actions'
import { refresh } from 'redux/modules/auth/actions'
import { LIVE_DATA_KEYS,COLUMN_WITH_SELECTION_ORIGIN } from 'helpers/utils'
import InputBox from './inputBox'

function EditableRow(props) {
    const { rowNumber, row, isLastRow, dataRows,
        handleRowChange, handleRemoveRow, 
        addNewRow, handleSelectRow, dropdownInput } = props
    // State to track the currently editing field for background clor change
    const [editingField, setEditingField] = useState(null)

    const [selectKeys, setSelectKeys] = useState({})

    useEffect(() => {
        let keys = {}
        var columnsWithSelect = {}
        if (dropdownInput.keys != undefined) {
            keys = JSON.parse(dropdownInput.keys)
            columnsWithSelect = {...keys}
            // columnsWithSelect["HASH"] = keys["HASH"]!=undefined?keys["HASH"]:COLUMN_WITH_SELECTION_ORIGIN["HASH"]
            columnsWithSelect["PLAY TYPE"] = keys["PLAY TYPE"]!=undefined?keys["PLAY TYPE"]:COLUMN_WITH_SELECTION_ORIGIN["PLAY TYPE"]

            // columnsWithSelect["OPP TEAM"] = keys["OPPONENT"]!=undefined?keys["OPPONENT"]:COLUMN_WITH_SELECTION_ORIGIN["OPPONENT"]
            columnsWithSelect["RESULT"] = keys["RESULTS"]!=undefined?keys["RESULTS"]:COLUMN_WITH_SELECTION_ORIGIN["RESULTS"]
            // columnsWithSelect["DEF FRONT"] = keys["DEF FRONT"]!=undefined?keys["DEF FRONT"]:COLUMN_WITH_SELECTION_ORIGIN["DEF FRONT"]
            // columnsWithSelect["STUNT"] = keys["STUNT"]!=undefined?keys["STUNT"]:COLUMN_WITH_SELECTION_ORIGIN["STUNT"]
            // columnsWithSelect["BLITZ"] = keys["BLITZ"]!=undefined?keys["BLITZ"]:COLUMN_WITH_SELECTION_ORIGIN["BLITZ"]
            // columnsWithSelect["COVERAGE"] = keys["COVERAGE"]!=undefined?keys["COVERAGE"]:COLUMN_WITH_SELECTION_ORIGIN["COVERAGE"]
            // columnsWithSelect["OFF PLAY TREE"] = keys["OFF PLAY TREE"]!=undefined?keys["OFF PLAY TREE"]
            // :COLUMN_WITH_SELECTION_ORIGIN["OFF PLAY TREE"]
            // columnsWithSelect["OFF FORM TREE"] = keys["OFF FORM TREE"]!=undefined?keys["OFF FORM TREE"]
            // :COLUMN_WITH_SELECTION_ORIGIN["OFF FORM TREE"]
            // columnsWithSelect["GAP"] = keys["GAP"]!=undefined?keys["GAP"]:COLUMN_WITH_SELECTION_ORIGIN["GAP"]
            // columnsWithSelect["OFF FORM"] = keys["OFF FORM"]!=undefined?keys["OFF FORM"]:COLUMN_WITH_SELECTION_ORIGIN["OFF FORM"]
            // columnsWithSelect["MOTION"] = keys["MOTION"]!=undefined?keys["MOTION"]:COLUMN_WITH_SELECTION_ORIGIN["MOTION"]
            // columnsWithSelect["PASS PRO"] = keys["PASS PRO"]!=undefined?keys["PASS PRO"]:COLUMN_WITH_SELECTION_ORIGIN["PASS PRO"]
            // columnsWithSelect["OFF PLAY"] = keys["OFF PLAY"]!=undefined?keys["OFF PLAY"]:COLUMN_WITH_SELECTION_ORIGIN["OFF PLAY"]
            setSelectKeys(columnsWithSelect)
        }else{
            columnsWithSelect["PLAY TYPE"] = COLUMN_WITH_SELECTION_ORIGIN["PLAY TYPE"]
            columnsWithSelect["RESULT"] = COLUMN_WITH_SELECTION_ORIGIN["RESULTS"]
            setSelectKeys(columnsWithSelect)
        }
    }, [dropdownInput])

    const handleFocus = (key) => {
        setEditingField(key)
        if (isLastRow) {
            addNewRow()
        }
    }

    // Helper function to render the correct input type
    const renderInput = (key, value) => {
        
        if (selectKeys[key]) { // If the column has specified select options
            if (key == 'OPP TEAM' && rowNumber != 2) {
                // return <InputBox
                //     name={key}
                //     fromValue={value}
                //     row={row}
                //     isLastRow = {isLastRow}
                //     onFocus={() => handleFocus(key)}
                //     onBlur={() => setEditingField(null)}
                //     addNewRow={addNewRow}
                //     handleRowChange={handleRowChange}
                //     handleSelectRow={handleSelectRow}
                // />
                return <input
                    type="text"
                    autoComplete='off'
                    name={key}
                    value={value}
                    onFocus={() => handleFocus(key)}
                    onBlur={() => setEditingField(null)}
                    onChange={(e) => handleRowChange(row.id, e.target.name,e.target.value)}
                />
            }
            return <SearchSelect
                handleFocus={() => handleFocus(key)}
                setEditingField={() => setEditingField(null)}
                handleRowChange={(e) => handleRowChange(row.id, e.target.name, e.target.value)}
                list={selectKeys[key]!=undefined?selectKeys[key]:[]} key={key} value={value} keyName={key} />
        } else { // Default to text input
            if (key == 'PLAY #' && rowNumber !== 1) {
                // return <InputBox
                //     name={key}
                //     fromValue={value}
                //     row={row}
                //     isLastRow = {isLastRow}
                //     onFocus={() => handleFocus(key)}
                //     onBlur={() => setEditingField(null)}
                //     addNewRow={addNewRow}
                //     handleRowChange={handleRowChange}
                //     handleSelectRow={handleSelectRow}
                // />
                return <input
                    type="text"
                    autoComplete='off'
                    name={key}
                    value={value}
                    onFocus={() => handleFocus(key)}
                    onBlur={() => setEditingField(null)}
                    onChange={(e) => handleRowChange(row.id, e.target.name, e.target.value)}
                />
            }
            return (
                <input
                    type="text"
                    autoComplete='off'
                    name={key}
                    value={value}
                    onFocus={() => handleFocus(key)}
                    onBlur={() => setEditingField(null)}
                    onChange={(e) => handleRowChange(row.id, e.target.name, e.target.value)}
                />
            )
        }
    }

    const timerIdRef = useRef(null)
    return (
        <tr className={row.isSelected ? 'selectedRow' : ''}>
            {/* {Object.keys(row).map((key, index) => { */}
            {LIVE_DATA_KEYS.map((key, index) => {
                if (key === 'id') {
                    return <></>
                    return (<td
                        onMouseDown={() => {
                            handleSelectRow(row.id)
                            // clearTimeout(timerIdRef.current)
                            // timerIdRef.current = setTimeout(() => {
                            //     handleSelectRow(row.id)
                            // }, 1000)
                        }}
                        onMouseUp={() => {
                            console.log("called the cliear timer function")
                            clearTimeout(timerIdRef.current)
                        }}
                        key={index}
                        style={{
                            backgroundColor: editingField === key ? '#f0f0f0' : 'transparent', cursor: 'pointer'
                        }}
                    >
                        {rowNumber}
                    </td>)
                }
                else if (key !== 'isSelected'){
                    return (
                        <td
                            key={index}
                            style={{
                                backgroundColor: editingField === key ? '#a0a0a0' : 'transparent',
                            }}
                        >
                            {renderInput(key, row[key])}
                        </td>
                    )
                }
                    
            })}
            <td>
                <button onClick={() => handleRemoveRow(row.id)}><MdDeleteOutline /></button>
            </td>
        </tr>
    )
}

// export default EditableRow
const actions = {
    updateDropdownInput
}
EditableRow.propTypes = {
    dropdownInput: PropTypes.any,
}

const selector = createStructuredSelector({
    dropdownInput: dropdownInputSelector,
})

export default compose(connect(selector, actions))(EditableRow)
