import React, { useState, useRef, useEffect } from 'react'
import EditableRow from '../../../components/EditableRow'
import useStyles from './styles'
import * as cx from 'classnames'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { dropdownInputSelector, sheetStatusSelector, liveDataSelector, sheetNameSelector } from 'redux/modules/sheet/selectors'
import { getDropdownInput, updateDropdownInput, createLiveData, updateLiveData, getLiveData, clearLiveData } from 'redux/modules/sheet/actions'
import { refresh } from 'redux/modules/auth/actions'
import { LIVE_DATA_KEYS, OFFENSIVE_PLAYS_KEYS, DEFENSE_KEYS } from 'helpers/utils'
import { get_gn_ls } from '../../../helpers/liveData'

const statsKeysOrigin =
{
  "passer": "Player #",
  "passing": "PASSING",
  "rusher": "Player #",
  "rushing": "RUSHING",
  "receiver": "Player #",
  "receiving": "RECEIVING"
}
const statsKeys = [
  "passer",
  "passing",
  "rusher",
  "rushing",
  "receiver",
  "receiving"
]

const formationsKeys = [
  "OFF FORMATIONS",
  "POINT",
  "YARDS",
  "AVG"
]

const tendenciesKeys = [
  "OFF FORM",
  "PASS",
  "RUN"
]

const scoutKeys = [
  "name",
  "#",
  "Avg",
  "Total"
]

const personnelKeys = [
  "name",
  "Play",
  "#",
  "Yards",
  "Avg"
]
var play01, play02, play03, play11, play12, play13, yard01, yard02, yard03, yard04, yard05
var yard11, yard12, yard13, yard14, yard15, yard21, yard22, yard23, yard24, yard25, yard26

var passingKeys = []
var passingValue = {}

var rushingKeys = []
var rushingValue = {}

var receiverKeys = []
var receiverValue = {}

function EachTab(props) {
  const classes = useStyles()
  const [liveDataRows, setRows] = useState([])
  const [tableMode, setTableMode] = useState("offensive")
  const [columnWidths, setColumnWidths] = useState({})
  const [statsColumnWidths, setStatsColumnWidths] = useState({})

  const [isResizing, setIsResizing] = useState(false)
  const [resizePos, setResizePos] = useState({ startX: 0, columnKey: '' })
  const [offensiveData, setOffensiveData] = useState([])
  const [defenseData, setDefenseData] = useState([])
  const [topPlays, setTopPlays] = useState([])
  const [tendencies, setTendencies] = useState({})
  const [selfScout, setSelfScout] = useState({})
  const [personnel, setPersonnel] = useState([])
  const [formations, setFormations] = useState([])
  const [longKeys, setLongKeys] = useState([])
  const [personnelCount, setPersonnelCount] = useState(1)
  const [formationCount, setFormationCount] = useState(1)
  const [minStaticWidth, setMinStaticWidth] = useState(62)

  const uniqueTopValues = (data, limit, keyText) => {
    // Initialize an empty object to store counts
    var counts = {}

    // Loop through the data and count occurrences
    for (var i = 0; i < data.length; i++) {
      if (data[i][keyText] != undefined && data[i][keyText] != "") {
        var value = data[i][keyText].toString()
        if (value !== "" && value !== null) { // Check if value is not empty or null
          counts[value] = (counts[value] || 0) + 1
        }
      }
    }

    // Convert counts object to an array of arrays [[value, count], ...]
    var countsArray = []
    for (var key in counts) {
      countsArray.push([key, counts[key]])
    }

    // Sort countsArray by count in descending order
    countsArray.sort(function (a, b) {
      return b[1] - a[1]
    })

    // Get the top 'limit' unique values
    var topValues = countsArray.slice(0, limit)

    // Extract the first column (unique values)
    var sortedList = []
    var sortedFinalData = {}

    var finalList = []

    console.log("topValues=======:", topValues)
    topValues.map((item) => {
      var newKey = item[0].toString()
      sortedFinalData[newKey] = {}

      data.map((row) => {
        if (row[keyText] == item[0]) {
          sortedList.push(data[keyText])
          sortedFinalData[newKey] = insertMapValue(sortedFinalData[newKey],
            row['OFF PLAY TREE'], row, 'GN/LS')
        }
      })
      item.push(sortedFinalData[newKey])
      finalList.push(item)
    })

    return finalList
  }

  const insertMapValue = (origin, key, row, vKey) => {
    if (origin[key] != undefined) {
      var count = origin[key]['count'] + 1
      var total = origin[key]['total']
      if (!isNaN(Number(row[vKey]))) {
        total = origin[key]['total'] + Number(row[vKey])
      }
      var avg = (total / count).toFixed(1)
      origin[key] = {
        "name": key,
        "count": count,
        "avg": avg,
        "total": total
      }
    } else {
      origin[key] = {
        "name": key,
        "count": 1,
        "avg": row[vKey],
        "total": isNaN(Number(row[vKey])) ? 0 : Number(row[vKey])
      }
    }
    return origin
  }

  useEffect(() => {
    var offensiveDataTmp = []
    var defenseDataTmp = []
    var tmpResultList = []
    ///OFFENSIVE PLAYS
    var topPlaysTmp = []
    var tendencyTmp = []
    var scoutTmp = {
      'STUNT': {},
      'BLITZ': {},
      'FRONT': {},
      'COVERAGE': {}
    }
    var personnelTmp = []
    var formationTmp = []
    if (props.liveData.content != undefined) {
      let contentData = props.liveData.content
      let allRow = []
      allRow = JSON.parse(contentData)
      allRow.map((row, index) => {
        if (row['ODK'] == "O") {
          offensiveDataTmp.push(row)
          tmpResultList.push(row['RESULT'])
          if (row['RESULT'] != "PENALTY") {
            topPlaysTmp.push(row)
          }
        }
        if (row['ODK'] == "D") {
          defenseDataTmp.push(row)
        }
        if (row['STUNT'] != "") {
          scoutTmp['STUNT'] = insertMapValue(scoutTmp['STUNT'], row['STUNT'], row, 'GN/LS')
        }
        if (row['BLITZ'] != "") {
          scoutTmp['BLITZ'] = insertMapValue(scoutTmp['BLITZ'], row['BLITZ'], row, 'GN/LS')
        }
        if (row['DEF FRONT'] != "") {
          scoutTmp['FRONT'] = insertMapValue(scoutTmp['FRONT'], row['DEF FRONT'], row, 'GN/LS')
        }
        if (row['COVERAGE'] != "") {
          scoutTmp['COVERAGE'] = insertMapValue(scoutTmp['COVERAGE'], row['COVERAGE'], row, 'GN/LS')
        }

        ///personnel data
        if (row['PERSONNEL'] != "") {
          personnelTmp.push(row)
        }

        ///formation data
        if (row['OFF FORM TREE'] != "") {
          formationTmp.push(row)
        }

        /// get long key between passer,rusher, and receiver
        if (row['RESULT'] != undefined) {
          if (row['PASSER'] != "" && row['PASSER'] != undefined) {
            var key = row["PASSER"].toString()
            if (!passingKeys.includes(key)) {
              passingKeys.push(key)
            }
            if (passingValue[key] == undefined) {
              passingValue[key] = 0
            }
            if (!isNaN(Number(row['GN/LS']))) {
              passingValue[key] = passingValue[key] + Number(row['GN/LS'])
            }
          }

          if (row['RUSHER'] != "" && row['RUSHER'] != undefined) {
            var key = row["RUSHER"].toString()
            if (!rushingKeys.includes(key)) {
              rushingKeys.push(key)
            }
            if (rushingValue[key] == undefined) {
              rushingValue[key] = 0
            }
            if (!isNaN(Number(row['GN/LS']))) {
              rushingValue[key] = rushingValue[key] + Number(row['GN/LS'])
            }
          }

          if (row['RECEIVER'] != "" && row['RECEIVER'] != undefined) {
            var key = row["RECEIVER"].toString()
            if (!receiverKeys.includes(key)) {
              receiverKeys.push(key)
            }
            if (receiverValue[key] == undefined) {
              receiverValue[key] = 0
            }
            if (!isNaN(Number(row['GN/LS']))) {
              receiverValue[key] = receiverValue[key] + Number(row['GN/LS'])
            }
          }
        }
      })
      console.log("personnelTmp====:", personnelTmp)

      var personnelTTemp = uniqueTopValues(personnelTmp, 3, 'PERSONNEL')
      console.log("personnelTTemp====:", personnelTTemp)
      var formationTTemp = uniqueTopValues(formationTmp, 3, 'OFF FORM TREE')

      offensiveDataTmp.sort(function (a, b) {
        return b['PLAY #'] - a['PLAY #']
      })

      topPlaysTmp.sort((a, b) => (b["GN/LS"] - a["GN/LS"]))

      var passConst = ["COMPLETE", "INCOMPLETE", "INTERCEPTION", "SACK", "COMPLETE,TD"]
      var runConst = ["RUSH", "RUSH,TD"]

      topPlaysTmp.map((row, index) => {
        if (tendencyTmp[row["OFF FORM"]] == undefined) {
          tendencyTmp[row["OFF FORM"]] = {
            "OFF FORM": row["OFF FORM"],
            "PASS": passConst.includes(row['RESULT']) ? 1 : 0,
            "RUN": runConst.includes(row['RESULT']) ? 1 : 0,
            "COUNT": 1
          }
        } else {
          var passValue = tendencyTmp[row["OFF FORM"]]["PASS"]
          var runValue = tendencyTmp[row["OFF FORM"]]["RUN"]
          var count = tendencyTmp[row["OFF FORM"]]["COUNT"]
          if (passConst.includes(row['RESULT'])) {
            passValue++
          }
          if (runConst.includes(row['RESULT'])) {
            runValue++
          }
          tendencyTmp[row["OFF FORM"]] = {
            "OFF FORM": row["OFF FORM"],
            "PASS": passValue,
            "RUN": runValue,
            "COUNT": count + 1
          }
        }
      })

      const tendencyTTemp = Object.keys(tendencyTmp).map((key) => {
        var yardValue = 0
        offensiveDataTmp.map((off) => {
          if (off['OFF FORM'] == tendencyTmp[key]['OFF FORM']) {
            if (!isNaN(Number(off['GN/LS']))) {
              yardValue += Number(off['GN/LS'])
            }
          }
        })
        return { ...tendencyTmp[key], "YARD": yardValue, "AVG": (yardValue / tendencyTmp[key]['COUNT']).toFixed(1) }
      })

      tendencyTTemp.sort((a, b) => (b["COUNT"] - a["COUNT"]))
      var play01Const = ["COMPLETE", "INCOMPLETE", "INTERCEPTION", "SACK"]

      play01 = tmpResultList.reduce((acc, currentValue) => {
        if (play01Const.includes(currentValue.toUpperCase())) {
          acc++
        }
        return acc
      }, 0)

      play02 = tmpResultList.filter(item => item.toUpperCase().startsWith("RUSH")).length
      play03 = Number(play01) + Number(play02)
      yard01 = 0
      var sumRush = 0
      var sumScramble = 0
      var sumSack = 0
      allRow.map((item, index) => {
        if (item['RESULT'] != undefined) {
          const condition1 = item['ODK'] === "O"
          const condition2 = item['RESULT'].toUpperCase().startsWith("COMPLETE")
          var gnV = Number(item['GN/LS'])
          if (isNaN(gnV)) {
            gnV = 0
          }
          if (condition1 && condition2) {
            yard01 += gnV
          }
          const conditionRush = item['RESULT'].toUpperCase().startsWith("RUSH")
          if (condition1 && conditionRush) {
            sumRush += gnV
          }
          const conditionScramble = item['RESULT'].toUpperCase().startsWith("SCRAMBLE")
          if (condition1 && conditionScramble) {
            sumScramble += gnV
          }
          const conditionSack01 = item['RESULT'].toUpperCase().startsWith("SACK")
          const conditionSack02 = !item['RESULT'].toUpperCase().startsWith("PENALTY")
          if (condition1 && conditionSack01 && conditionSack02) {
            sumSack += gnV
          }
        }
      })

      yard02 = sumRush + sumScramble + sumSack

      yard21 = 0
      yard23 = 0

      for (let i = 0; i < offensiveDataTmp.length; i++) {
        if (offensiveDataTmp[i]['RESULT'] === "PENALTY" && offensiveDataTmp[i]['GN/LS'] < 0) {
          yard23 += Number(offensiveDataTmp[i]['GN/LS'])
          yard21++
        }
      }

      yard22 = 0
      yard24 = 0
      play11 = 0
      play12 = 0
      yard11 = 0
      yard12 = 0
      for (let i = 0; i < defenseDataTmp.length; i++) {
        var oneDef = defenseDataTmp[i]
        var gnV = Number(oneDef['GN/LS'])
        if (isNaN(gnV)) {
          gnV = 0
        }
        if (oneDef['RESULT'] === "PENALTY" && gnV < 0) {
          yard23 += gnV
          yard22++
        }
        if (oneDef['PLAY TYPE'] === "PASS") {
          play11++
        }
        if (oneDef['PLAY TYPE'] === "RUN") {
          play12++
        }
        if (oneDef['RESULT'] == 'COMPLETE') {
          yard11 += gnV
        }
        if (oneDef['RESULT'].toUpperCase().startsWith("RUSH")) {
          yard12 += gnV
        } else if (oneDef['RESULT'].toUpperCase().startsWith("SCRAMBLE")) {
          yard12 += gnV
        } else if (oneDef['RESULT'].toUpperCase().startsWith("SACK")) {
          yard12 += gnV
        }
      }

      play13 = play11 + play12


      // yard03 = yard01 + yard02 + yard23
      yard03 = yard01 + yard02

      yard04 = getDivition(yard02, play02)

      yard05 = getDivition(yard01, play01)

      yard13 = yard11 + yard12

      yard14 = getDivition(yard12, play12)

      yard15 = getDivition(yard11, play11)

      yard25 = getTurnovers(offensiveDataTmp)
      yard26 = getTurnovers(defenseDataTmp)

      var tmpLong = []
      if (passingKeys.length >= receiverKeys.length) {
        tmpLong = passingKeys
      }
      if (rushingKeys.length > tmpLong.length) {
        tmpLong = rushingKeys
      }

      setLongKeys(tmpLong)
      setOffensiveData(offensiveDataTmp)
      setDefenseData(defenseDataTmp.sort((a, b) => b['PLAY #'] - a['PLAY #']))
      // setTopPlays(topPlaysTmp.sort((a, b) => b['PLAY #'] - a['PLAY #']))
      setTopPlays(topPlaysTmp)
      setTendencies(tendencyTTemp)
      setSelfScout(scoutTmp)
      setPersonnel(personnelTTemp)
      setFormations(formationTTemp)
      setFormationCount(formationTmp.length)
      setPersonnelCount(personnelTmp.length)
    }
    console.log("calculate by livedata in live state")
  }, [props.liveData])

  const getDivition = (a, b) => {
    try {
      if (b == 0) return a
      var tmp = a / b
      if (isNaN(tmp)) tmp = 0
      return tmp.toFixed(2)
    } catch (error) {
      return " "
    }
  }

  const getTurnovers = (data) => {
    let incompleteToCount = 0
    let rushToCount = 0
    let fumbleCount = 0
    let interceptionCount = 0
    let fumbleTdCount = 0
    let interceptionTdCount = 0

    data.forEach(item => {
      if (item['RESULT'] === "INCOMPLETE,TO") {
        incompleteToCount++
      } else if (item['RESULT'] === "RUSH,TO") {
        rushToCount++
      } else if (item['RESULT'] === "FUMBLE") {
        fumbleCount++
      } else if (item['RESULT'] === "INTERCEPTION") {
        interceptionCount++
      } else if (item['RESULT'] === "FUMBLE,TD") {
        fumbleTdCount++
      } else if (item['RESULT'] === "INTERCEPTION,TD") {
        interceptionTdCount++
      }
    })

    const result = incompleteToCount + rushToCount + fumbleCount + interceptionCount + fumbleTdCount + interceptionTdCount
    return result
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!isResizing) return
      const newWidth = Math.max(30, resizePos.startWidth + (event.clientX - resizePos.startX))
      setColumnWidths((prev) => ({ ...prev, [resizePos.columnKey]: newWidth }))
    }

    const handleMouseUp = () => setIsResizing(false)

    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isResizing, resizePos])

  const handleMouseDown = (columnKey, startWidth, event) => {
    setIsResizing(true)
    setResizePos({ startX: event.clientX, columnKey, startWidth })
  }

  return <>
    <div className='stateTab' style={{ width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'start', width: 'max-content' }}>
        <div style={{ marginRight: '10px', marginBottom: '20px' }}>
          <div style={{ marginBottom: '20px' }}>
            <div className={classes.liveState}>LIVE STATS</div>
            <div className='row'>
              <div className='sheet-item bl bt br bb' style={{ width: '250px' }}>PEARCE</div>
              <div className='sheet-item bt br bb grey-back' style={{ width: '100px' }}>YARDS</div>
            </div>
            <div className='sheet-item bl br bb' style={{ width: '350px' }}>PLAYS</div>
            <div className='center-start'>
              <div className='sheet-item bl br bb' style={{ width: '100px' }}>{play01}</div>
              <div className='sheet-item br bb underline grey-back' style={{ width: '150px' }}>PASSING</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard01}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bl br bb' style={{ width: '100px' }}>{play02}</div>
              <div className='sheet-item br bb underline grey-back' style={{ width: '150px' }}>RUSHING</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard02}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bl br bb' style={{ width: '100px' }}>{play03}</div>
              <div className='sheet-item br bb' style={{ width: '150px' }}>TOTAL</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard03}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bl br grey-back' style={{ width: '100px' }}></div>
              <div className='sheet-item br bb underline grey-back' style={{ width: '150px' }}>AVG RUSH</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard04}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bl br grey-back' style={{ width: '100px' }}></div>
              <div className='sheet-item br bb underline grey-back' style={{ width: '150px' }}>AVG PASS</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard05}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bl br bb red-back' style={{ width: '250px' }}>OPPONENT</div>
              <div className='sheet-item br bb grey-back' style={{ width: '100px' }}>YARDS</div>
            </div>

            {/* ////// */}
            <div className='sheet-item bl br bb' style={{ width: '350px' }}>PLAYS</div>
            <div className='center-start'>
              <div className='sheet-item bl br bb' style={{ width: '100px' }}>{play11}</div>
              <div className='sheet-item br bb underline grey-back' style={{ width: '150px' }}>PASSING</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard11}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bl br bb' style={{ width: '100px' }}>{play12}</div>
              <div className='sheet-item br bb underline grey-back' style={{ width: '150px' }}>RUSHING</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard12}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bl br bb' style={{ width: '100px' }}>{play13}</div>
              <div className='sheet-item br bb' style={{ width: '150px' }}>TOTAL</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard13}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bl br grey-back' style={{ width: '100px' }}></div>
              <div className='sheet-item br bb underline grey-back' style={{ width: '150px' }}>AVG RUSH</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard14}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bb bl br grey-back' style={{ width: '100px' }}></div>
              <div className='sheet-item br bb underline grey-back' style={{ width: '150px' }}>AVG PASS</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard15}</div>
            </div>

            <div className='sheet-item bl br bb grey-back' style={{ width: '350px' }}>PENALTIES</div>
            <div className='center-start'>
              <div className='sheet-item bl br bb blue-back' style={{ width: '250px' }}>PEARCE</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard21}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bl br bb red-back' style={{ width: '250px' }}>OPPONENT</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard22}</div>
            </div>
            {/* <div className='sheet-item bl br bb grey-back' style={{ width: '350px' }}>PENALTY YARDS</div>
        <div className='center-start'>
          <div className='sheet-item bl br bb blue-back' style={{ width: '250px' }}>PEARCE</div>
          <div className='sheet-item br bb' style={{ width: '100px' }}>{yard23}</div>
        </div>
        <div className='center-start'>
          <div className='sheet-item bl br bb red-back' style={{ width: '250px' }}>OPPONENT</div>
          <div className='sheet-item br bb' style={{ width: '100px' }}>{yard24}</div>
        </div> */}
            <div className='sheet-item bl br bb grey-back' style={{ width: '350px' }}>TURNOVERS</div>
            <div className='center-start'>
              <div className='sheet-item bl br bb blue-back' style={{ width: '250px' }}>PEARCE</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard25}</div>
            </div>
            <div className='center-start'>
              <div className='sheet-item bl br bb red-back' style={{ width: '250px' }}>OPPONENT</div>
              <div className='sheet-item br bb' style={{ width: '100px' }}>{yard26}</div>
            </div>
          </div>
          <div>
            <div className={classes.liveState}>PLAYER STATS</div>
            <div className="inputTable" style={{ marginRight: '10px' }}>
              <table>
                <thead>
                  <tr>
                    {
                      statsKeys.map((head) => {
                        return <th key={head} style={{
                          minWidth: `${statsColumnWidths[head] != undefined ? statsColumnWidths[head] : minStaticWidth}px`,
                        }}>
                          <div style={{ position: 'relative', userSelect: 'none', height: '20px' }}>
                            {statsKeysOrigin[head]}
                            <div
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: -2,
                                bottom: 0,
                                width: '5px',
                                cursor: 'col-resize',
                              }}
                              onMouseDown={(e) => handleMouseDown(head, statsColumnWidths[head] != undefined ? statsColumnWidths[head] : 100, e)}
                            />
                          </div>
                        </th>
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    longKeys.map((key, index) => {
                      return <tr style={{
                        backgroundColor: 'transparent',
                      }}>
                        <td>{passingKeys[index]}</td>
                        <td>{passingValue[passingKeys[index]]}</td>
                        <td>{rushingKeys[index]}</td>
                        <td>{rushingValue[rushingKeys[index]]}</td>
                        <td>{receiverKeys[index]}</td>
                        <td>{receiverValue[receiverKeys[index]]}</td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div style={{ marginRight: '10px', marginBottom: '20px' }}>
          <div className={classes.liveState}>
            <select name='tableMode' onChange={(e) => { setTableMode(e.target.value) }}>
              <option value="offensive">Offensive Plays</option>
              <option value="defense">Defense</option>
            </select>
          </div>
          <div className={cx(tableMode == "offensive" ? "" : classes.hide, "inputTable", "offensiveTable")}>
            <div className="inputTable" style={{ marginRight: '10px' }}>
              <table className='inline-block'>
                <thead>
                  <tr>
                    {
                      OFFENSIVE_PLAYS_KEYS.map((key, index) => {
                        return <th
                          style={{
                            minWidth: `${columnWidths[key] != undefined ? columnWidths[key] : minStaticWidth}px`,
                          }}
                        ><div style={{ position: 'relative', userSelect: 'none' }}>
                            {key}
                            <div
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: -2,
                                bottom: 0,
                                width: '5px',
                                cursor: 'col-resize',
                              }}
                              onMouseDown={(e) => handleMouseDown(key, columnWidths[key] != undefined ? columnWidths[key] : 100, e)}
                            />
                          </div></th>
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    offensiveData.map((row, index) => {
                      return <tr style={{
                        backgroundColor: 'transparent',
                      }}>
                        {
                          OFFENSIVE_PLAYS_KEYS.map((key) => {
                            return <td
                              style={{
                                backgroundColor: key == "GN/LS" ? get_gn_ls(row[key]) : 'transparent',
                                color: key == "GN/LS" ? 'black' : 'white'
                              }}>{row[key]}</td>
                          })

                        }
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className={cx(tableMode == "offensive" ? classes.hide : "", "inputTable", "defenseTable")}>
            <table>
              <thead>
                <tr>
                  {
                    DEFENSE_KEYS.map((key, index) => {
                      return <th
                        style={{
                          minWidth: `${columnWidths[key] != undefined ? columnWidths[key] : minStaticWidth}px`,
                        }}
                      ><div style={{ position: 'relative', userSelect: 'none' }}>
                          {key}
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: -2,
                              bottom: 0,
                              width: '5px',
                              cursor: 'col-resize',
                            }}
                            onMouseDown={(e) => handleMouseDown(key, columnWidths[key] != undefined ? columnWidths[key] : 100, e)}
                          />
                        </div></th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  defenseData.map((row, index) => {
                    return <tr>
                      {
                        DEFENSE_KEYS.map((key) => {
                          if (key == "GN/LS")
                            return <td
                              style={{
                                backgroundColor: get_gn_ls(row[key]),
                                color: 'black'
                              }}>{row[key]}</td>
                          return <td>{key == "YN LN" ? row['Yard LN']
                            : key == 'DOWN' ? row['QTR']
                              : key == 'OFF PLAY' ? row['OFF PLAY TREE']
                                : key == 'OFF FORM' ? row['OFF FORM TREE']
                                  : row[key]}</td>
                        })
                      }
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        {tableMode == "offensive" ? <div>
          <div className={classes.liveState}>SELF SCOUT</div>
          <div className={classes.secondTitle}>TOP 15 FORMATIONS</div>
          <div className="inputTable" style={{ marginRight: '10px' }}>
            <table>
              <thead>
                <tr>
                  {
                    formationsKeys.map((head) => {
                      return <th key={head} style={{
                        minWidth: `${statsColumnWidths[head] != undefined ? statsColumnWidths[head] : minStaticWidth}px`,
                      }}>
                        <div style={{ position: 'relative', userSelect: 'none', height: '20px' }}>
                          {head == 'POINT' ? offensiveData.length : head}
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: -2,
                              bottom: 0,
                              width: '5px',
                              cursor: 'col-resize',
                            }}
                            onMouseDown={(e) => handleMouseDown(head, statsColumnWidths[head] != undefined ? statsColumnWidths[head] : 100, e)}
                          />
                        </div>
                      </th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(tendencies).map((key, index) => {
                    return <tr style={{
                      backgroundColor: 'transparent',
                    }}>
                      <td>{tendencies[key]['OFF FORM']}</td>
                      <td>{tendencies[key]['COUNT']}</td>
                      <td>{tendencies[key]['YARD']}</td>
                      <td>{tendencies[key]['AVG']}</td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
        </div> : <></>}
        {tableMode == "offensive" ? <div>
          <div className={classes.liveState}>TENDENCIES</div>
          <div className={classes.secondTitle}>PASS/RUN</div>
          <div className="inputTable" style={{ marginRight: '10px' }}>
            <table>
              <thead>
                <tr>
                  {
                    tendenciesKeys.map((head) => {
                      return <th key={head} style={{
                        minWidth: `${statsColumnWidths[head] != undefined ? statsColumnWidths[head] : minStaticWidth}px`,
                      }}>
                        <div style={{ position: 'relative', userSelect: 'none', height: '20px' }}>
                          {head}
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: -2,
                              bottom: 0,
                              width: '5px',
                              cursor: 'col-resize',
                            }}
                            onMouseDown={(e) => handleMouseDown(head, statsColumnWidths[head] != undefined ? statsColumnWidths[head] : 100, e)}
                          />
                        </div>
                      </th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(tendencies).map((key, index) => {
                    return <tr style={{
                      backgroundColor: 'transparent',
                    }}>
                      <td>{tendencies[key]['OFF FORM']}</td>
                      <td>{tendencies[key]['PASS']}</td>
                      <td>{tendencies[key]['RUN']}</td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
        </div> : <></>}
        {tableMode != "offensive" ?
          <div>
            <div className={classes.liveState}>SELF SCOUT</div>
            <div className={classes.secondTitle} style={{ color: 'transparent' }}>TOP</div>
            {
              Object.keys(selfScout).map((scout) => {
                return <div className="inputTable" style={{ marginRight: '10px', marginBottom: '10px', height: 'fit-content' }}>
                  <table>
                    <thead>
                      <tr>
                        {
                          scoutKeys.map((head) => {
                            return <th key={head} style={{
                              minWidth: `${statsColumnWidths[head] != undefined ? statsColumnWidths[head] : minStaticWidth}px`,
                            }}>
                              <div style={{ position: 'relative', userSelect: 'none', height: '20px' }}>
                                {head == 'name' ? scout : head}
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: -2,
                                    bottom: 0,
                                    width: '5px',
                                    cursor: 'col-resize',
                                  }}
                                  onMouseDown={(e) => handleMouseDown(head, statsColumnWidths[head] != undefined ? statsColumnWidths[head] : 100, e)}
                                />
                              </div>
                            </th>
                          })
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.keys(selfScout[scout]).map((key, index) => {

                          return <tr style={{
                            backgroundColor: 'transparent',
                          }}>
                            <td>{selfScout[scout][key]['name']}</td>
                            <td>{selfScout[scout][key]['count']}</td>
                            <td>{selfScout[scout][key]['avg']}</td>
                            <td>{selfScout[scout][key]['total']}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              })
            }
          </div> : <></>}
        {tableMode != "offensive" ? <div>
          <div>
            <div className={classes.liveState}>PERSONNEL</div>
            <div className={classes.secondTitle}></div>
            <div style={{ marginRight: '10px', marginBottom: '10px' }}>
              <table className='percentTable' style={{ marginBottom: '10px' }}>
                <thead>
                  <tr>
                    <th>TOP 3</th>
                    <th>#</th>
                    <th>%</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    personnel.map((item) => {
                      return <tr style={{
                        backgroundColor: 'transparent',
                      }}>
                        <td>{item[0]}</td>
                        <td>{item[1]}</td>
                        <td>{(item[1] / personnelCount * 100).toFixed(2).toString() + "%"}</td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
              {
                personnel.map((item) => {
                  var newRows = Object.keys(item[2])
                  newRows.sort((a, b) => (a.localeCompare(b)))
                  return <div className="percentContent"
                    style={{ marginRight: '10px', marginBottom: '10px', height: 'fit-content' }}>
                    <table>
                      <thead>
                        <tr>
                          {
                            personnelKeys.map((head) => {
                              return <th key={head} style={{
                                minWidth: `${statsColumnWidths[head] != undefined ? statsColumnWidths[head] : minStaticWidth}px`,
                              }}>
                                <div style={{ position: 'relative', userSelect: 'none', height: '20px' }}>
                                  {head == 'name' ? item[0] : head}
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      right: -2,
                                      bottom: 0,
                                      width: '5px',
                                      cursor: 'col-resize',
                                    }}
                                    onMouseDown={(e) =>
                                      handleMouseDown(head,
                                        statsColumnWidths[head] != undefined
                                          ? statsColumnWidths[head] : 100, e)}
                                  />
                                </div>
                              </th>
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          newRows.map((inner, index) => {
                            if (index > 4) {
                              return <></>
                            }
                            return <tr style={{
                              backgroundColor: 'transparent',
                            }}>
                              <td></td>
                              <td>{item[2][inner]['name']}</td>
                              <td>{item[2][inner]['count']}</td>
                              <td>{item[2][inner]['total']}</td>
                              <td>{item[2][inner]['avg']}</td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                })
              }
            </div>
          </div>
          <div>
            <div className={classes.liveState}>FORMATIONS</div>
            <div className={classes.secondTitle}></div>
            <div style={{ marginRight: '10px', marginBottom: '10px' }}>
              <table className='percentTable' style={{ marginBottom: '10px' }}>
                <thead>
                  <tr>
                    <th>TOP 3</th>
                    <th>#</th>
                    <th>%</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    formations.map((item) => {
                      return <tr style={{
                        backgroundColor: 'transparent',
                      }}>
                        <td>{item[0]}</td>
                        <td>{item[1]}</td>
                        <td>{(item[1] / formationCount * 100).toFixed(2).toString() + "%"}</td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
              {
                formations.map((item) => {
                  var newRows = Object.keys(item[2])
                  newRows.sort((a, b) => (a.localeCompare(b)))
                  return <div className="percentContent" style={{
                    marginRight: '10px',
                    marginBottom: '10px', height: 'fit-content'
                  }}>
                    <table>
                      <thead>
                        <tr>
                          {
                            personnelKeys.map((head) => {
                              return <th key={head} style={{
                                minWidth: `${statsColumnWidths[head] != undefined ?
                                  statsColumnWidths[head] : minStaticWidth}px`,
                              }}>
                                <div style={{ position: 'relative', userSelect: 'none', height: '20px' }}>
                                  {head == 'name' ? item[0] : head}
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      right: -2,
                                      bottom: 0,
                                      width: '5px',
                                      cursor: 'col-resize',
                                    }}
                                    onMouseDown={(e) =>
                                      handleMouseDown(head,
                                        statsColumnWidths[head] != undefined ?
                                          statsColumnWidths[head] : 100, e)}
                                  />
                                </div>
                              </th>
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          newRows.map((inner, index) => {
                            if (index > 4) {
                              return <></>
                            }
                            return <tr style={{
                              backgroundColor: 'transparent',
                            }}>
                              <td></td>
                              <td>{item[2][inner]['name']}</td>
                              <td>{item[2][inner]['count']}</td>
                              <td>{item[2][inner]['total']}</td>
                              <td>{item[2][inner]['avg']}</td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                })
              }
            </div>
          </div>
        </div> : <></>}
        {tableMode == "offensive" ? <div style={{ marginRight: '10px' }}>
          <div className={cx("inputTable", "offensiveTable")}>
            <div className={classes.liveState}>TOP PLAYS</div>
            <div className={classes.secondTitle}>&nbsp;</div>
            <table>
              <thead>
                <tr>
                  {
                    OFFENSIVE_PLAYS_KEYS.map((key, index) => {
                      return <th
                        style={{
                          minWidth: `${columnWidths[key] != undefined ? columnWidths[key] : minStaticWidth}px`,
                        }}
                      ><div style={{ position: 'relative', userSelect: 'none' }}>
                          {key}
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: -2,
                              bottom: 0,
                              width: '5px',
                              cursor: 'col-resize',
                            }}
                            onMouseDown={(e) => handleMouseDown(key, columnWidths[key] != undefined ? columnWidths[key] : 100, e)}
                          />
                        </div></th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  topPlays.map((row, index) => {
                    return <tr style={{
                      backgroundColor: 'transparent',
                    }}>
                      {
                        OFFENSIVE_PLAYS_KEYS.map((key) => {
                          return <td
                            style={{
                              backgroundColor: key == "GN/LS" ? get_gn_ls(row[key]) : 'transparent',
                              color: key == "GN/LS" ? 'black' : 'white'
                            }}>{row[key]}</td>
                        })
                      }
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
        </div> : <></>}
      </div>
    </div>
  </>
}

const actions = {
  getDropdownInput,
  updateDropdownInput,
  createLiveData,
  updateLiveData,
  getLiveData,
  refresh,
  clearLiveData,
}

const selector = createStructuredSelector({
  currentUser: currentUserSelector,
  dropdownInput: dropdownInputSelector,
  sheetStatus: sheetStatusSelector,
  liveData: liveDataSelector,
  sheetName: sheetNameSelector
})

export default compose(connect(selector, actions))(EachTab)