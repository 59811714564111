import React, { useState, useRef, useEffect } from 'react'
import EditableRow from '../../../components/EditableRow'
import useStyles from './styles'
import * as cx from 'classnames'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import {
  dropdownInputSelector, sheetStatusSelector, liveDataSelector,
  sheetNameSelector
} from 'redux/modules/sheet/selectors'
import {
  getDropdownInput, updateDropdownInput, createLiveData, updateLiveData, updateLocalLiveData,
  getLiveData, clearLiveData
} from 'redux/modules/sheet/actions'
import { refresh } from 'redux/modules/auth/actions'
import { QTR_V, PDOWN_V, ODK, OPPTEAM, CV, GNLS_V, DN_V, DIST_V } from '../../../helpers/liveData'
import { downloadStringAsCsv } from '../../../helpers/downloadStringAsCsv'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
import { roles } from 'helpers/AppConfig'
import { CustomAlert } from 'components/CustomAlert'
import { LIVE_DATA_KEYS } from 'helpers/utils'
import Papa from "papaparse"
import { CirclesWithBar } from 'react-loader-spinner'
import socketIO from 'socket.io-client'
const socket = socketIO.connect('https://sheet.mygametrax.com', {
  path: '/mysocket'
})

function LiveData(props) {
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const [liveDataRows, setRows] = useState([])
  const [tableKeys, setTableKeys] = useState(LIVE_DATA_KEYS)
  const [columnWidths, setColumnWidths] = useState({})
  const [scaleSize, setScaleSize] = useState("scale100")
  const [isResizing, setIsResizing] = useState(false)
  const [resizePos, setResizePos] = useState({ startX: 0, columnKey: '' })

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [alertMode, setAlertMode] = useState('error')

  const [selectedFile, setSelectedFile] = useState(null)
  const [downkey, setDownKey] = useState("aaa")
  const [loadingData, setLoadingData] = useState(true)
  const [payStatus, setPayStatus] = useState(false)

  let params = useParams()
  let paramId = params.id
  useEffect(() => {
    if (paramId != undefined) {
      setLoadingData(true)
      props.getLiveData({
        id: paramId,
        success: (data) => {
          let contentData = data.data.content
          // parsingContentOld(contentData)
          parsingContent(contentData)
          setLoadingData(false)
        },
        fail: (err) => {
          console.log("live====err:", err)
          setRows([])
          setLoadingData(false)
        }
      })
    } else {
      dispatch(clearLiveData())
    }
    socket.emit('join_room', { username: props.currentUser.name, room: paramId })
  }, [location])

  useEffect(() => {
    console.log("update by socket===:")
    socket.on('update_livedata', (data) => {
      parsingContent(data.message)
    })

    // Remove event listener on component unmount
    // return () => socket.off('update_livedata')
  }, [socket])

  useEffect(() => {
    if (props.currentUser.paypals != undefined) {
      if (props.currentUser.paypals.length > 0) {
        if (new Date(props.currentUser.paypals[0].current_period_end) > new Date()) {
          setPayStatus(true)
        }
      }
    }
  }, [props.currentUser])

  const parsingContent = (contentData) => {
    let allRow = JSON.parse(contentData)
    props.updateLocalLiveData({
      id: paramId,
      body: {
        "id": paramId,
        "user": props.currentUser.id,
        "content": contentData,
        "docName": props.sheetName
      }
    })
    setRows(allRow)
  }

  const handleSelectRow = (id) => {
    const newRows = liveDataRows.map(row => {
      if (row.id === id) {
        return { ...row, isSelected: !row.isSelected }
      }
      return row
    })
    setRows(newRows)
  }

  const handleAddRow = () => {
    const newRow = tableKeys.reduce((acc, key) => {
      if (key === "id") {
        acc[key] = new Date().getTime()
      } else {
        acc[key] = "" // or any value you want to assign
      }
      return acc
    }, {})

    if (liveDataRows.length == 0) {
      newRow['PLAY #'] = 1
    }

    // const newRow = { id: liveDataRows.length + 1, name: '', age: '', country: '', isSelected: false}
    setRows([...liveDataRows, newRow])
    if (liveDataRows.length == 0) {
      if (props.liveData.id == undefined) {
        props.createLiveData({
          body: {
            "user": props.currentUser.id,
            "id": paramId,
            "content": JSON.stringify([newRow]),
            "type": "livedata",
            "docName": props.sheetName
          }
        })
      }
    }
  }

  const handleRemoveRow = (id) => {
    let newRows = []
    newRows = liveDataRows.filter(row => row.id !== id)
    setRows(newRows)
    updateServer(newRows)
  }

  const handleRowChange = async (id, name, value) => {
    // const { name, value } = event.target
    console.log("newDate in row change==00:",new Date().getTime())
    let tmpNewRows = liveDataRows.map((row
    ) => {
      if (row.id == id) {
        return { ...row, [name]: value.toUpperCase() }
      }
      return row
    })
    console.log("newDate in row change==01:",new Date().getTime())
    console.log("tmpNewRows====:", tmpNewRows[0])
    let newRows = []
    let indexValue = -1
    let qtr, pDown, dv, bv, cv, ev, gnlsv, dist
    indexValue = tmpNewRows.findIndex((row) => row.id === id)
    if (indexValue == 0 && name == "GN/LS") {
      newRows = tmpNewRows
    }
    else if ((indexValue == 0 || indexValue == 1)
      && (name == "DN" || name == "DIST" || name == "Yard LN" || name == "PLAY #")) {
      newRows = tmpNewRows
    } else {
      if (name == "Yard LN" && indexValue >= 2) {
        ///calculate pre row value:
        gnlsv = GNLS_V(tmpNewRows[indexValue - 1]['RESULT'] ?? "", tmpNewRows[indexValue - 1]['Yard LN'], value)
        tmpNewRows[indexValue - 1]['GN/LS'] = gnlsv
      }

      var forNewRows = []

      for (var index = 0; index < tmpNewRows.length; index++) {
        var row = tmpNewRows[index]

        if (indexValue == 0 && name == "OPP TEAM") {
          if (index == 0) {
            bv = value
          } else {
            dv = ODK(row['OFF FORM'], row['DEF FRONT'], row['RESULT'], row['PLAY TYPE'] ?? "")
            bv = OPPTEAM(dv ?? "", value)
          }
          forNewRows.push({ ...row, 'OPP TEAM': bv })
          continue
        }

        if (indexValue == 0 && name == 'QTR') {
          if (index == 0) {
            qtr = value
          } else {
            qtr = QTR_V(row['RESULT'], forNewRows[index - 1]['QTR'])
          }
          forNewRows.push({ ...row, 'QTR': qtr })
          continue
        }
        if (row.id === id) {
          dv = ODK(row['OFF FORM'], row['DEF FRONT'], row['RESULT'], row['PLAY TYPE'] ?? "")
          if (index == 0) {
            bv = row['OPP TEAM']
            gnlsv = row['GN/LS']
            cv = "1"
          } else {
            bv = OPPTEAM(dv ?? "", forNewRows[index - 1]["OPP TEAM"])
            cv = CV(forNewRows[index - 1]["ODK"], forNewRows[index - 1]["PLAY #"])
            if (tmpNewRows.length > index + 1) {
              gnlsv = GNLS_V(row['RESULT'] ?? "", row['Yard LN'], tmpNewRows[index + 1]['Yard LN'])
            } else {
              gnlsv = GNLS_V(row['RESULT'] ?? "", row['Yard LN'], "")
            }
          }
          if (index > 0) {
            qtr = QTR_V(row['RESULT'], forNewRows[index - 1]['QTR'])
            pDown = PDOWN_V(forNewRows[index - 1]['ODK'], row['ODK'])
            ev = DN_V(forNewRows[index - 1]['RESULT'] ?? "", forNewRows[index - 1]['DN'], row['Yard LN'],
              forNewRows[index - 1]['GN/LS'], forNewRows[index - 1]['DIST'])
            dist = DIST_V(forNewRows[index - 1]['DIST'],
              row['DIST'],
              row['Yard LN'],
              forNewRows[index - 1]['DN'],
              row['DN'],
              forNewRows[index - 1]['GN/LS'],
              forNewRows[index - 1]['RESULT']
            )
          }
          if (index > 1) {
            forNewRows.push({
              ...row,
              "QTR": qtr,
              "P DOWN": pDown,
              "ODK": dv,
              "OPP TEAM": bv,
              "PLAY #": cv,
              "GN/LS": gnlsv,
              "DN": ev,
              "DIST": dist,
            })
          } else {
            forNewRows.push({
              ...row,
              "QTR": qtr,
              "P DOWN": pDown,
              "ODK": dv,
              "OPP TEAM": bv,
              "PLAY #": cv,
              "GN/LS": gnlsv,
            })
          }
        } else {
          forNewRows.push(row)
        }
      }
      newRows = [...forNewRows]
    }
    // console.log("newDate in row change==03:",new Date().getTime())
    // setRows(newRows)
    console.log("newDate in row change==04:",new Date().getTime())
    var newRowsAll = []

    for (var index = 0; index < newRows.length; index++) {
      var row = newRows[index]
      if (index < indexValue || index == indexValue || index == newRows.length - 1) {
        newRowsAll.push(row)
        continue
      }
      if (indexValue == 0 && name == "OPP TEAM") {
        dv = ODK(row['OFF FORM'], row['DEF FRONT'], row['RESULT'], row['PLAY TYPE'] ?? "")
        bv = OPPTEAM(dv ?? "", newRowsAll[index - 1]["OPP TEAM"])
        row = { ...row, 'OPP TEAM': bv }
      }
      if (indexValue == 0 && name == 'QTR') {
        if (index == 0) {
          qtr = value
        } else {
          qtr = QTR_V(row['RESULT'], newRowsAll[index - 1]['QTR'])
        }
        row = { ...row, 'QTR': qtr }
      }

      dv = ODK(row['OFF FORM'], row['DEF FRONT'], row['RESULT'], row['PLAY TYPE'] ?? "")
      if (index == 0) {
        bv = row['OPP TEAM']
        cv = 1
        gnlsv = row['GN/LS']
      } else {
        bv = OPPTEAM(dv ?? "", newRowsAll[index - 1]["OPP TEAM"])
        cv = CV(newRowsAll[index - 1]["ODK"], newRowsAll[index - 1]["PLAY #"])
        if (newRows.length > index + 1) {
          gnlsv = GNLS_V(row['RESULT'] ?? "", row['Yard LN'], newRows[index + 1]['Yard LN'])
        } else {
          gnlsv = GNLS_V(row['RESULT'] ?? "", row['Yard LN'], "")
        }
      }
      if (index > 0) {
        qtr = QTR_V(row['RESULT'], newRowsAll[index - 1]['QTR'])
        pDown = PDOWN_V(newRowsAll[index - 1]['ODK'], row['ODK'])
        ev = DN_V(newRowsAll[index - 1]['RESULT'] ?? "", newRowsAll[index - 1]['DN'], row['Yard LN'],
          newRowsAll[index - 1]['GN/LS'], newRowsAll[index - 1]['DIST'])
        dist = DIST_V(newRowsAll[index - 1]['DIST'],
          row['DIST'],
          row['Yard LN'],
          newRowsAll[index - 1]['DN'],
          row['DN'],
          newRowsAll[index - 1]['GN/LS'],
          newRowsAll[index - 1]['RESULT']
        )
      }
      if (index > 1) {
        row = {
          ...row,
          "QTR": qtr,
          "P DOWN": pDown,
          "ODK": dv,
          "OPP TEAM": bv,
          "PLAY #": cv,
          "GN/LS": gnlsv,
          "DN": ev,
          "DIST": dist,
        }
      } else {
        row = {
          ...row,
          "QTR": qtr,
          "P DOWN": pDown,
          "ODK": dv,
          "OPP TEAM": bv,
          "PLAY #": cv,
          "GN/LS": gnlsv,
        }
      }
      newRowsAll.push(row)
    }
    console.log("newDate in row change==05:",new Date().getTime())
    setRows(newRowsAll)
    console.log("newDate in row change==06:",new Date().getTime())
    updateServer(newRowsAll)
  }

  const updateServer = async (newRows) => {
    let contentData = JSON.stringify(newRows)
    var sheetName = ""
    if (props.sheetName != "") {
      sheetName = props.sheetName
    } else {
      sheetName = "Untitled Sheet"
    }
    await props.updateLiveData({
      id: paramId,
      body: {
        "id": paramId,
        "user": props.currentUser.id,
        "content": contentData,
        "docName": sheetName
      }
    })
    await socket.emit('update_livedata', {
      username: props.currentUser.name,
      room: paramId,
      livedata: contentData
    })
    console.log("newDate in row change==09:",new Date().getTime())
  }

  const handleDownload = () => {

    if (props.currentUser.role == roles[0]) {
      setAlertMode("error")
      setAlertOpen(true)
      setAlertText("Please upgrade your account as premium")
      return
    }
    let contentData = ""

    LIVE_DATA_KEYS.map(key => {
      if (key == "id") {
        // contentData += ","
      } else {
        contentData += key + ","
      }
    })

    contentData = contentData.slice(0, -1)
    contentData += "\n"

    liveDataRows.map(row => {
      {
        tableKeys.map((key) => {
          if (key == "id") {
            // contentData += ","
          } else if (row[key] != undefined) {
            if (row[key].toString().includes(",")) {
              contentData += "\"" + row[key].toString() + "\"" + ","
            } else {
              contentData += row[key].toString() + ","
            }
          } else {
            contentData += ","
          }
        })
      }
      contentData = contentData.slice(0, -1) ///remove the last , in the line
      contentData += "\n"
    })
    contentData = contentData.slice(0, -1) ///remove the last empty line
    if (props.liveData.id != undefined) {
      downloadStringAsCsv(contentData, props.sheetName + "_" + props.liveData.id.toString() + ".csv")
    } else {
      downloadStringAsCsv(contentData, props.sheetName + ".csv")
    }
  }

  const handleOnChange = (e) => {
    setSelectedFile(e.target.files[0])
    setRows([])

    const files = e.target.files
    const keyRow = []
    if (files) {
      Papa.parse(files[0], {
        complete: function (results) {
          let newRowsFile = []
          let emptyIndexs = []
          results.data.map((ele, index) => {
            if (index == 0) {
              ele.map((item, order) => {
                if (item != "") {
                  keyRow.push(item)
                } else {
                  emptyIndexs.push(order)
                }
              })
            } else {
              let eleRow = []///remove value if key doesn't exist.
              ele.map((item, order) => {
                if (!emptyIndexs.includes(order)) {
                  eleRow.push(item)
                }
              })
              let oneRow = { "id": new Date().getTime() - 3000 + index }
              eleRow.map((item, index) => {
                oneRow = { ...oneRow, [keyRow[index]]: item }
              })
              if (oneRow['ODK'] == "" || oneRow['ODK'] == undefined) {
                oneRow['ODK'] = ODK(oneRow['OFF FORM'], oneRow['DEF FRONT'],
                  oneRow['RESULT'], oneRow['PLAY TYPE'] ?? "")
              }
              newRowsFile.push(oneRow)
            }
          })
          setDownKey(downkey + "a")
          setRows(newRowsFile)

          updateServer(newRowsFile)
        }
      }
      )
    }
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!isResizing) return
      const newWidth = Math.max(30, resizePos.startWidth + (event.clientX - resizePos.startX))
      setColumnWidths((prev) => ({ ...prev, [resizePos.columnKey]: newWidth }))
    }

    const handleMouseUp = () => setIsResizing(false)

    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isResizing, resizePos])

  const handleMouseDown = (columnKey, startWidth, event) => {
    setIsResizing(true)
    setResizePos({ startX: event.clientX, columnKey, startWidth })
  }

  const handleChangeScale = (e) => {
    setScaleSize(e.target.value)
  }

  return <>
    <div className="featureBtnRow center-start">
      <Button variant="primary" onClick={handleAddRow}>Add Row</Button>
      <Button variant="primary" onClick={handleDownload}>Download</Button>
      {payStatus && <>
        <input
        type="file"
        id="file-upload"
        onChange={handleOnChange}
        accept='.csv'
        style={{ display: 'none' }}
      /><button onClick={() => document.getElementById('file-upload').click()}>
      Upload File
    </button></>}
      
      <select name="expanded" id="expand" value={scaleSize} onChange={handleChangeScale}>
        <option value="scale50">50%</option>
        <option value="scale75">75%</option>
        <option value="scale90">90%</option>
        <option value="scale100">100%</option>
        <option value="scale125">125%</option>
        <option value="scale150">150%</option>
        <option value="scale200">200%</option>
      </select>
      {
        loadingData ? <span className="center-center" style={{ color: 'green' }}>&nbsp;&nbsp;&nbsp;Loading...
          <CirclesWithBar
            height="25"
            width="25"
            radius="3"
            color="green"
            ariaLabel="loading"
          /></span> : <></>
      }
      {selectedFile && (
        <p style={{ color: 'white' }}>&nbsp;&nbsp; Selected file: {selectedFile.name}</p>
      )}
    </div>
    <div className='inputTable' key={downkey} style={{ maxHeight: 'calc(100vh - 175px)', marginBottom: '0px' }}>
      <table className={cx(scaleSize)} id={scaleSize}>
        <thead>
          <tr>
            {tableKeys.map((head) => {
              return head === "id" ?
                <></>
                // <th className='idbox'>1</th>
                : <th key={head} style={{
                  minWidth: columnWidths[head] != undefined ? `${columnWidths[head]}px` : 'fit-content', whiteSpace: 'nowrap'
                }}>
                  <div style={{ position: 'relative', userSelect: 'none' }}>
                    {head}
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: -2,
                        bottom: 0,
                        width: '5px',
                        cursor: 'col-resize',
                      }}
                      onMouseDown={(e) => handleMouseDown(head, columnWidths[head] != undefined ? columnWidths[head] : 100, e)}
                    />
                  </div>
                </th>
            })}
          </tr>
        </thead>
        <tbody>
          {liveDataRows.map((row, index) => (
            <EditableRow
              key={row.id}
              rowNumber={index + 2}
              row={row}
              isLastRow={index === liveDataRows.length - 1}
              dataRows={liveDataRows}
              addNewRow={handleAddRow}
              handleRowChange={handleRowChange}
              handleRemoveRow={handleRemoveRow}
              handleSelectRow={handleSelectRow}
            />
          ))}
        </tbody>
      </table>
      <CustomAlert
        isOpen={alertOpen}
        type={alertMode}
        text={alertText}
        onClose={() => setAlertOpen(false)}
      />
    </div>
  </>
}

LiveData.propTypes = {
  currentUser: PropTypes.any,
  sheetStatus: PropTypes.any,
  refresh: PropTypes.func,
  getDropdownInput: PropTypes.func,
  updateDropdownInput: PropTypes.func,
  getLiveData: PropTypes.func,
  createLiveData: PropTypes.func,
  updateLiveData: PropTypes.func,
  updateLocalLiveData: PropTypes.func,
  clearLiveData: PropTypes.func,
  liveData: PropTypes.any,
  sheetName: PropTypes.any
}

const actions = {
  getDropdownInput,
  updateDropdownInput,
  createLiveData,
  updateLiveData,
  updateLocalLiveData,
  getLiveData,
  refresh,
  clearLiveData,
}

const selector = createStructuredSelector({
  currentUser: currentUserSelector,
  dropdownInput: dropdownInputSelector,
  sheetStatus: sheetStatusSelector,
  liveData: liveDataSelector,
  sheetName: sheetNameSelector
})

export default compose(connect(selector, actions))(LiveData)