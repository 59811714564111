import React from 'react'
import {
  Container,
  Grid,
  Button,
  IconButton
} from '@material-ui/core'
import useStyles from './Footer.styles'
import white_logo from 'assets/images/logo/logo.svg'
import white_sheet_logo from 'assets/images/logo/sheet_logo.png'
import heart_small from 'assets/images/Icon/heart_small.svg'
import * as cx from 'classnames'

import { Link, useHistory } from 'react-router-dom'
import { CustomIconButton } from 'components/CustomIconButton'
import footer_lock from 'assets/images/images/lock.svg'
import footer_stripe from 'assets/images/images/stripe.svg'
import { HiOutlineArrowRight } from 'react-icons/hi'
import icon_facebook from 'assets/images/images/icon_facebook.svg'
import icon_twitter from 'assets/images/images/icon_twitter.svg'
import icon_linkedin from 'assets/images/images/icon_linkedin.svg'

const Footer = (props) => {
  const classes = useStyles()
  const history = useHistory()


  const handleMoveToStart = () => {
    props.moveToStart()
  }

  return (
    <div className={cx(classes.root, 'pb-10')}>
      <Container>
        <Grid container spacing={0} className='pb-30'>
          <Grid item xs={4} >
            <div>
              <div className={classes.bold} onClick={()=>history.push('/about')}>About</div>
              <div className={classes.bold} onClick={()=>history.push('/terms')}>Terms & Conditions</div>
              <div className={classes.bold} onClick={()=>history.push('/policy')}>Privacy Policy</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <div>
                <IconButton>
                  <img src={icon_twitter} alt=""/>
                </IconButton>
                <IconButton>
                  <img src={icon_facebook} alt=""/>
                </IconButton>
                <IconButton>
                  <img src={icon_linkedin} alt=""/>
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.footerBottom}>
          <Grid item xs={8} className='center-start'>© 2024. All Rights Reserved by MyGameTrax</Grid>
          <Grid item xs={4}>
            <div className={classes.centerStartColumn}>
              <div>Mygametrax@gmail.com</div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Footer
